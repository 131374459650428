html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.webviewer {
  flex: 1 1;
  margin: 8px;
  box-shadow: 1px 1px 10px #999;
}

.App {
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
}

.App .header {
  width: 100%;
  height: 60px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #00a5e4;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}
.webviewer{
  height:100vh
}

